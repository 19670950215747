.AnyDayTitle {
  font-size: 23px;
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(250, 250, 253);
}

.AnyDayName {
  font-size: 28px;
  font-family: Arial, Helvetica, sans-serif;
  background: lightsteelblue;
}

.BookSuperslim {
  font-size: 22px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #f1efef;
}

.BookSlimTitle {
  font-size: 24px;
  font-family: Arial, Helvetica, sans-serif;
}

.GroupRow {
  font-size: 22px;
  font-family: Arial, Helvetica, sans-serif;
}

.sortTitle {
  font-size: 42px;
  font-family: Arial, Helvetica, sans-serif;
  background: rgb(226, 228, 234);
}

.sortSubTitle {
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-style: italic;
  background: rgb(226, 228, 234);
}

.sortTitleMobile {
  font-size: 24px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  background: rgb(226, 228, 234);
}

.sortSubTitleMobile {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  font-style: italic;
  background: rgb(226, 228, 234);
}

.SmallButton {
  --bs-btn-padding-y: .15rem;
  --bs-btn-padding-x: .3rem;
  --bs-btn-font-size: .75rem;
}

.BestSong {
  font-size: 20px;
  background: khaki;
}

.Title {
    background: khaki;
  }
  
.Title2 {
font-size: 13px;
background: khaki;
}

.grape_white {
  font-size: 15px;
  color: rgb(93, 91, 91);
  }

.grape_red {
  font-size: 15px;
  }

.TitleSightsCat {
  font-size: 18px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  background: rgb(235, 235, 232);
}

.DestinationTitle {
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  background: khaki;
}

.PeopleName {
  font-size: 16px;
  background: khaki;
}

.PeopleName18 {
  font-size: 18px;
  background: khaki;
}

.Header {
  font-size: 22px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  margin: "30px 0";
  background: wheat;
}

.PeopleHeader {
  font-size: 16px;
  background-color: #f1efef;
}

.PeopleHeader2 {
  font-size: 16px;
  background: rgb(176, 196, 222);
  text-align: center;
}

.Text11 {
  font-size: 11px;
  }

.Text13 {
  font-size: 13px;
  }

.Text13WS {
  font-size: 13px;
  white-space: pre-wrap;
  }

.Text18 {
  font-size: 18px;
  }

.Text28 {
  font-size: 28px;
  }

.QuoteOld {
  font-family: 'Fondamento';
  font-size: 18px;
  white-space: pre-wrap;
}

.Quote {
  font-family: 'Helvetica';
  font-size: 14px;
  white-space: pre-wrap;
}

.quote16 {
  font-size: 16px;
  white-space: pre-wrap;
}

.quote14 {
  font-size: 14px;
  white-space: pre-wrap;
}

.quote12 {
  font-size: 12px;
  white-space: pre-wrap;
}

.quote13 {
  font-size: 13px;
  white-space: pre-wrap;
}

.ArtTitle {
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    /* height: 30px; */
    /* margin: "20px 0"; */
    background: khaki;
    /* font-style: italic; */
  }

.Description {
    font-size: 13px;
    white-space: pre-wrap;
}

.Description2 {
    font-size: 14px;
    white-space: pre-wrap;
}

.factText {
    background-color: #f1efef;
    font-size: 13px;
    white-space: pre-wrap;
  }

.factTextNoWrap {
    background-color: #f1efef;
    font-size: 13px;
  }

.rickStevesText {
    background-color: #bdf6bc;
    font-size: 13px;
    white-space: pre-wrap;
  }

.factText14 {
    background-color: #f1efef;
    font-size: 13px;
    white-space: pre-wrap;
  }

  .factText16 {
    background-color: #f1efef;
    font-size: 16px;
    white-space: pre-wrap;
  }

.SmallText {
    font-size: 12px;
  }

  .backgroud {
    background: khaki;
  }
  
  .background13 {
    font-size: 13px;
    background: khaki;
  }

  .Date {
    font-size: 13px;
    background: rgb(229, 231, 235);
  }
  
  .DateTxt {
    font-size: 13px;
    background: rgb(229, 231, 235);
    white-space: pre-wrap;
  }

  .Text14bold {
    font-size: 14px;
    font-weight: bold;
  }

  .SummaryChapter {
    font-size: 14px;
    /* font-weight: bold; */
    font-family: Arial, Helvetica, sans-serif;
    /* height: 30px;
    margin: "20px 0"; */
    background: rgb(235, 235, 232);
    /* font-style: italic; */
  }
  
  .SummaryChapter2 {
    font-size: 16px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    background: rgb(235, 235, 232);
    /* white-space: pre-wrap; */
  }

  .SummaryChapter3 {
    font-size: 16px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    background: rgb(235, 235, 232);
    /* white-space: pre-wrap; */
  }

  .center {
    text-align: center;
    /* border: 3px solid green; */
  }

  .YourHighlightClass {
    color: rgb(7, 7, 7);
    background-color: #07f713;
  }

  .blockquote {
    color: #666;
    margin: 0;
    padding-left: 1em;
    border-left: 0.2em #eee solid;
    font-size: 17px;
  }
  .blockquote-small {
    color: #666;
    margin-bottom: 3px;
    padding-left: 1em;
    border-left: 0.2em #eee solid;
    font-size: 14px;
  }

  .blockquote-source {
    color: rgb(39, 38, 38);
    margin: 0;
    padding-left: 1em;
    font-style: italic;
    font-size: 14px;
  }

  .blockquote-source-small {
    color: rgb(39, 38, 38);
    margin-top: 3px;
    padding-left: 1em;
    font-style: italic;
    font-size: 14px;
  }

  .search-container {
    width: 222px;
    height: 51px;
    padding: 10px;
    background: #f3f3f3;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: none;
   }

   .search input {
    border-radius: 5px;
    border: 1px solid #ccc;
    height: 37px;
    width: 255px;
    margin-left: 1px;
    padding: 12px;
  }

  .searchSmall {
    border-radius: 5px;
    width: 200px;
    padding-left: 7px;
    padding-top: 3px;
    padding-bottom: 3px;
    border: 1px solid #c6c0c0;
    font-size: 16px;
}

.searchPeople {
  border-radius: 5px;
  width: 200px;
  padding-left: 7px;
  padding-top: 1px;
  padding-bottom: 1px;
  border: 1px solid #c6c0c0;
  font-size: 16px;
}

